import React from 'react'
import esplogo from '../components/assets/img/esplogo.svg';

function Happiness() {
  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: 'white' }}>
        <div className="card-title" style={{ textAlign: 'center', margin: '5% 0' }}>
          <h1 className="card-title">Employee Happiness Survey 2024</h1>
          <p style={{ marginBottom: '0', marginTop:'8px' }}>Human Resource Department</p>
          <p>Chatpandas</p>
          <hr />
          <img src={esplogo} alt="" />
        </div>
        <div className="card-block" style={{ textAlign: 'center', margin: '5% 0' }}>
          <p style={{ margin: '3% 10%' }}>
            The Employee Engagement Survey of Chatpandas Will be conducted in 2024 with the aim to measure perceptions, attitude, job satisfaction and engagements of all staff members. The first of its kind, the survey is meant to provide key insights and employees preferences about workplace issues, personal growth, learning opportunities, access to resources, leadership, among other topics. The results of the survey and further analysis will help the company to direct resources and shape continuous improvement efforts with the aim to attract and develop talent and enable employees to participate fully in supporting company values and priorities.
          </p>
          <hr />
          <img src={esplogo} alt="" />
        </div>
      </div>
    </>
  )
}

export default Happiness