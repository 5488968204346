import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../components/assets/css/auth.css';
import { emailUrl, employeeCodeUrl } from '../env';

function Epslogin() {
  const [employeeCode, setEmployeeCode] = useState('');
  const [email, setEmail] = useState('');
  const [department, setDepartment] = useState('');
  const [error, setError] = useState({ employeeCode: '', email: '', form: '' });
  const [anonymous, setAnonymous] = useState(false);
  const [anonymousValue, setAnonymousValue] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.style.backgroundColor = 'rgb(25,25,25)';
    document.body.style.backgroundColor = 'rgb(25,25,25)';
    document.body.style.background = ' linear-gradient(359deg, rgba(25,25,25,1) 8%, rgba(59,15,146,1) 40%, rgba(25,25,25,1) 96%)';

    return () => {
      document.documentElement.style.backgroundColor = '';
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    setAnonymousValue(anonymous);
  }, [anonymous]);

  // Debounce function to delay API calls
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  // API call to check if the employee code exists
  const checkEmployeeCode = async (value) => {
    try {
      const response = await fetch(`${employeeCodeUrl}?employee_code=${value}`);
      const data = await response.json();
      console.log('employee code response', data);
      if (!response.ok) {
        setError((prevError) => ({
          ...prevError,
          employeeCode: data.detail || 'There was an error checking the employee code.'
        }));
      }
      // else if  (!data.exists) {
      //   setError((prevError) => ({
      //     ...prevError,
      //     employeeCode: 'Employee code does not exist.'
      //   }));
      // } else {
      //   setError((prevError) => ({
      //     ...prevError,
      //     employeeCode: ''
      //   }));
      // }
      if (data.message === 'Employee code exists' && data.form_submitted === false) {
        setError((prevError) => ({
          ...prevError,
          employeeCode: ''
        }));
      } else if (data.message === 'Employee code exists' && data.form_submitted === true) {
        setError((prevError) => ({
          ...prevError,
          employeeCode: 'You have already submitted the form.'
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          employeeCode: "Employee doesn't exist"
        }));
      }

    } catch (error) {
      console.error('Error checking employee code:', error);
      setError((prevError) => ({
        ...prevError,
        employeeCode: 'There was an error checking the employee code.'
      }));
    }
  };

  // API call to check if the email exists
  const checkEmail = async (value) => {
    try {
      const response = await fetch(`${emailUrl}?email=${value}`);
      const data = await response.json();

      if (!response.ok) {
        setError((prevError) => ({
          ...prevError,
          email: data.detail || 'There was an error checking the email.'
        }));
      } else if (data.exists) {
        setError((prevError) => ({
          ...prevError,
          email: 'Email already exists.'
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          email: ''
        }));
      }
    } catch (error) {
      console.error('Error checking email:', error);
      setError((prevError) => ({
        ...prevError,
        email: 'There was an error checking the email.'
      }));
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCheckEmployeeCode = useCallback(
    debounce((value) => {
      if (value) {
        checkEmployeeCode(value);
      }
    }, 2000),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCheckEmail = useCallback(
    debounce((value) => {
      if (value) {
        checkEmail(value);
      }
    }, 2000),
    []
  );
  const handleEmployeeCodeChange = (e) => {
    const value = e.target.value;
    const numericPattern = /^[0-9]*$/;
    if (numericPattern.test(value)) {
      setEmployeeCode(value);
      debouncedCheckEmployeeCode(value);
      setError((prevError) => ({
        ...prevError,
        employeeCode: ''
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        employeeCode: 'Employee code must contain only numbers.'
      }));
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    debouncedCheckEmail(value);
  };
  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
    if (error.form) {
      setError((prevError) => ({
        ...prevError,
        form: ''
      }));
    }
  };
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Clear previous form error messages
    setError((prevError) => ({
      ...prevError,
      form: ''
    }));
    // Perform final validation before submission
    if (!employeeCode || !email || !department) {
      setError((prevError) => ({
        ...prevError,
        form: 'Please fill out all fields.'
      }));
      return;
    } else if (employeeCode.length < 5) {
      setError((prevError) => ({
        ...prevError,
        form: 'Employee Code must be greater than 4 digits.'
      }));
      return;
    } else if (!validateEmail(email)) {
      setError((prevError) => ({
        ...prevError,
        form: 'Please enter a valid email address.'
      }));
      return;
    }

    if (error.employeeCode || error.email) {
      return;
    }

    navigate('/ehsdetail', {
      state: {
        employeeCode: employeeCode,
        email: email,
        department: department,
        anonymous: anonymousValue
      },
    });
  };

  return (
    <div className="container py-5" style={{ height: '100vh', width: '100vw' }}>
      <div className="row">
        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div id="container" style={{ backgroundColor: '#202020', color: '#fbfbfb' }}>
            <div className="forms">
              <div className="form-content">
                <div className="login-form">
                  <div className="title" style={{ color: '#fbfbfb' }}>Chat Pandas</div>
                  <p className="text" style={{ color: '#fbfbfb' }}>Please Enter User Information</p>
                  {/* Error message */}
                  {error.form && <div className="alert alert-danger">{error.form}</div>}
                  {error.employeeCode && <div className="alert alert-danger">{error.employeeCode}</div>}
                  {error.email && <div className="alert alert-danger">{error.email}</div>}
                  <form id="loginForm" onSubmit={handleSubmit}>
                    <div className="input-boxes">
                      <div className="input-box">
                        <input
                          type={anonymous ? "password" : "text"}
                          name="empCode"
                          id="empCode"
                          placeholder="Enter Employee Code"
                          style={{ backgroundColor: '#202020', color: '#fbfbfb' }}
                          value={employeeCode}
                          onChange={handleEmployeeCodeChange}
                        />
                      </div>
                      <div className="input-box">
                        <input
                          type={anonymous ? "password" : "email"}
                          id="empEmail"
                          name="empEmail"
                          placeholder="Enter your Email"
                          style={{ backgroundColor: '#202020', color: '#fbfbfb' }}
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </div>
                      <div className="input-box">
                        <select
                          className="form-control form-control-sm"
                          value={department}
                          onChange={handleDepartmentChange}
                        >
                          <option value="" disabled style={{ display: 'none' }}>Select Your Department</option>
                          <option value="gracious homes (GH)">Gracious Homes (GH)</option>
                          <option value="cabinets to go">Cabinets to go</option>
                          <option value="riders share">Riders Share</option>
                          <option value="rv share">RVShare</option>
                          <option value="Civic Plus">Civic Plus</option>
                          <option value="restaurant365">Restaurant365</option>
                          <option value="Gems At Work">Gems At Work</option>
                          <option value="Data & Dev">Dev&Data</option>
                          <option value="HR">HR</option>
                          <option value="Accounts">Accounts</option>
                          <option value="T&D">T&D</option>
                          <option value="Project Management">Project Management</option>
                          <option value="Business Development">Business Development</option>
                          <option value="Marketing">Marketing</option>
                          <option value="QC">QC</option>
                          <option value="BLK Vouge">BLK Vouge</option>
                          <option value="admin">Admin</option>
                        </select>
                      </div>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={anonymous}
                        onChange={(e) => setAnonymous(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="exampleCheck1">Do you want to stay anonymous</label>
                      <div className="button input-box ">
                        <input
                          type="submit"
                          value="Let's Get Started"
                          style={{ backgroundColor: '#510add' }}
                        />
                      </div>
                      <div className="text sign-up-text"></div>
                    </div>
                  </form>
                  <p style={{textAlign: 'end',fontSize: '11px',margin: '0px',position:'absolute',bottom:'10px',right:'10px'}}>Developed by: Data & Dev</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Epslogin;
