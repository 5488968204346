import React from 'react'
import dimentions from './assets/img/dimentions.PNG';
import scroing from './assets/img/scoring.PNG';
function Dimensions() {
  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: 'white' }}>
        <div className="card-title" style={{ textAlign: 'center' }}>
          <h2 className="card-title">Dimensions of Engagement </h2>
          <p style={{ marginBottom: '0' }}>In order to assess the employee engagement, responses will collected around the eight dimensions covering vital factors required for employees to be engaged at workplace. These numbers represent the total number of questions in every
            section. </p>
          <hr />
          <div className="img-responsive">
          <img src={dimentions} alt="" style={{width:'80%'}} />
          </div>
        </div>
        <div className="card-block" style={{ textAlign: 'center' }}>
          <h2 className="card-title">Scoring </h2>
          <p style={{ margin: '3% 10%' }}>
            There are total 76 question and employees are required to express their agreement to the given statement / question using a five point scale from ‘strongly agree’ to ‘strongly disagree’. Almost all of the question, other than few in ‘intent to stay’ were framed from a positive perspective, so it is reasonable to conclude that higher the score the greater the degree of engagement/satisfaction.
          </p>
          <hr />
          <div className="img-responsive">
          <img src={scroing} alt="" style={{width:'80%'}} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Dimensions